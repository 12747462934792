<template>
  <div class="home">
    <p class="welcome-message">
      {{ $t("welcomeMsg") }}, <span>{{ userName }}!</span>
    </p>
    <transition name="fade">
      <div v-if="sportsAreas.length && showGraph" class="streaming-msg">
        <p>{{ $t("messageYou") }}</p>
      </div>
    </transition>
    <div v-if="sportsAreas.length && showGraph" id="graph">
      <div v-if="selectedCategory" class="selected-category">
        <SportsCard
          :sport="selectedCategory.target.__data__"
          @close="selectedCategory = null"
        />
      </div>
      <button class="buttonDone" @click="reselectSportsAreas">
        {{ $t("reselectSports") }}
      </button>
      <p
        v-if="hoverMessage"
        class="hover-message"
        :style="{ top: hoverPosition.y + 'px', left: hoverPosition.x + 'px' }"
        v-html="hoverMessage"
      ></p>
    </div>
    <div class="categorySelection" v-else>
      <CategorySelection />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import * as d3 from "d3";
import SportsCard from "@/components/SportsCard.vue";
import CategorySelection from "@/components/CategorySelection.vue";

export default {
  components: {
    SportsCard,
    CategorySelection,
  },
  data() {
    return {
      selectedCategory: null,
      simulation: null,
      hoverMessage: "",
      hoverPosition: { x: 0, y: 0 },
    };
  },
  computed: {
    ...mapState(["userName", "sportsAreas", "showGraph"]),
    ...mapGetters(["getSportName"]),
    allLogsEmpty() {
      return this.sportsAreas.every((area) => area.logs.length === 0);
    },
  },
  watch: {
    showGraph(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.updateGraph();
        });
      }
    },
  },
  mounted() {
    if (this.showGraph) {
      this.$nextTick(() => {
        this.updateGraph();
      });
    }
    // Add the resize event listener
    window.addEventListener("resize", this.updateGraph);
  },
  beforeUnmount() {
    // Remove the resize event listener
    window.removeEventListener("resize", this.updateGraph);
  },
  methods: {
    reselectSportsAreas() {
      if (window.confirm(this.$t("deselectSports"))) {
        this.$store.commit("setShowGraph", {
          showGraph: null,
        });
      }
    },
    updateGraph() {
      // Stop the previous simulation if it exists
      if (this.simulation) {
        this.simulation.stop();
      }

      let svg = d3.select("#graph").select("svg");

      if (svg.empty()) {
        svg = d3
          .select("#graph")
          .append("svg")
          .attr("width", "100%")
          .attr("height", "100%");
      }

      svg.selectAll("*").remove(); // Remove existing SVG elements

      let width, height;

      if (svg.node() !== null) {
        width = +svg.node().getBoundingClientRect().width;
        height = +svg.node().getBoundingClientRect().height;
      }

      // Create a deep copy of each node in the sportsAreas array
      let sportsAreasCopy = this.sportsAreas.map((node) => ({ ...node }));

      let chargeStrength;
      if (width > 800) {
        chargeStrength = -50;
      } else if (width > 600) {
        chargeStrength = -30;
      } else {
        chargeStrength = -15;
      }

      let nodeRadius;
      if (width > 800) {
        nodeRadius = 60;
      } else if (width > 600) {
        nodeRadius = 50;
      } else {
        nodeRadius = 45;
      }

      // Create the new simulation with the copied data
      this.simulation = d3
        .forceSimulation(sportsAreasCopy)
        .force("center", d3.forceCenter(width / 2, height / 2))
        .force("charge", d3.forceManyBody().strength(chargeStrength))
        .force("collision", d3.forceCollide().radius(30))
        .on("tick", ticked);

      const node = svg
        .selectAll(".node")
        .data(sportsAreasCopy)
        .enter()
        .append("circle")
        .attr("class", "node")
        .attr("r", nodeRadius)
        .attr("fill", "url(#nodeGradient)") // Use gradient for fill
        .attr("stroke", "#ffffff") // Add a white border
        .attr("stroke-width", 2) // Border thickness
        .style("filter", "drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2))") // Add shadow
        .on("click", (d) => {
          this.selectedCategory = d;
        })
        .on("mouseover", (event, d) => {
          d3.select(event.currentTarget)
            .transition()
            .duration(500)
            .attr("r", nodeRadius + 10) // Increase size on hover
            .style("filter", "drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.4))"); // Enhance shadow
          this.handleHover(event, d);
        })
        .on("mouseout", (event, d) => {
          d3.select(event.currentTarget)
            .transition()
            .duration(500)
            .attr("r", nodeRadius) // Reset size
            .style("filter", "drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2))"); // Reset shadow
          this.handleMouseOut(event, d);
        })
        .on("touchstart", (event, d) => {
          d3.select(event.currentTarget)
            .transition()
            .duration(500)
            .attr("r", nodeRadius + 10)
            .style("filter", "drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.4))");
          this.handleHover(event, d);
        })
        .on("touchend", (event, d) => {
          d3.select(event.currentTarget)
            .transition()
            .duration(500)
            .attr("r", nodeRadius)
            .style("filter", "drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2))");
          this.handleMouseOut(event, d);
        })
        .call(
          d3
            .drag()
            .on("start", (event, d) => {
              if (!event.active) this.simulation.alphaTarget(0.3).restart(); // Restart simulation
              d.fx = d.x; // Fix x position
              d.fy = d.y; // Fix y position
            })
            .on("drag", (event, d) => {
              d.fx = event.x; // Update x position
              d.fy = event.y; // Update y position
            })
            .on("end", (event, d) => {
              if (!event.active) this.simulation.alphaTarget(0); // Stop simulation
              d.fx = null; // Release x position
              d.fy = null; // Release y position
            })
        );

      // Add gradient definition
      const defs = svg.append("defs");
      defs
        .append("linearGradient")
        .attr("id", "nodeGradient")
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "100%")
        .selectAll("stop")
        .data([
          { offset: "0%", color: "#578d68" }, // Start color
          { offset: "100%", color: "#81c784" }, // End color
        ])
        .enter()
        .append("stop")
        .attr("offset", (d) => d.offset)
        .attr("stop-color", (d) => d.color);

      const label = svg
        .selectAll(".label")
        .data(sportsAreasCopy)
        .enter()
        .append("text")
        .attr("class", "label")
        .text((d) => this.$t(this.getSportName(d.id)))
        .style("text-anchor", "middle")
        .style("alignment-baseline", "middle")
        .style("fill", "#fff");

      function ticked() {
        node
          .attr("cx", function (d) {
            return d.x;
          })
          .attr("cy", function (d) {
            return d.y;
          });

        label
          .attr("x", function (d) {
            return d.x;
          })
          .attr("y", function (d) {
            return d.y;
          });
      }
      // Start the simulation after defining the ticked function
      this.simulation.on("tick", ticked);
    },
    handleHover(event) {
      if (this.allLogsEmpty) {
        this.hoverMessage = this.$t("hoverMessage");
        this.hoverPosition = { x: event.pageX, y: event.pageY - 20 };
      } else {
        this.hoverMessage = "";
      }
    },
    handleMouseOut() {
      this.hoverMessage = "";
    },
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

span {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: #0b731d;
  padding: 5px;
  border-radius: 2px;
}

#graph {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: 200px;
}

.node:hover {
  cursor: pointer;
}

.categorySelection {
  max-height: 70vh;
  max-width: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.streaming-msg {
  animation: streaming 2s ease-in-out;
}

@keyframes streaming {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.buttonDone {
  background-color: white;
  border: none;
  color: #0b731d;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
  border-radius: 20px;
  position: fixed;
  bottom: 50px;
  left: 8px;
}

.buttonDone:hover {
  background-color: #c7d4c8;
}

.hover-message {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 20px;
  /* Increased for more rounded shape */
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #515950;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  pointer-events: none;
  white-space: nowrap;
  transform: translate(-50%, -100%);
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .hover-message {
    font-size: 13px;
    padding: 8px;
  }
}

@media (max-width: 992px) {
  .hover-message {
    font-size: 12px;
    padding: 7px;
  }
}

@media (max-width: 768px) {
  .hover-message {
    font-size: 11px;
    padding: 6px;
  }
}

@media (max-width: 576px) {
  .hover-message {
    font-size: 10px;
    padding: 5px;
  }
}
</style>
